import React from "react";
import clogo from "../../images/Credai-logo.png";
import { Link } from "gatsby";
import Logo from "./Logo";
import SocialIcons from "./SocialIcons";

function Footer() {
  return (
    <footer className="footer bg-light">
      <div className="footer-pad border-bottom">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-4 d-flex justify-content-center justify-content-lg-start mb-md-0 mb-2 flex-wrap">
              <a href="tel:+919840477777">+91 9840477777</a>
              <span className="ml-2 mr-2"> | </span>
              <a href="mailto:enquiry@bashyamgroup.com">
                enquiry@bashyamgroup.com
              </a>
            </div>
            <div className="col-md-4 d-flex justify-content-center align-items-md-center  mb-md-0 mb-2">
              <SocialIcons />
            </div>
            <div className="col-md-4 d-flex justify-content-center justify-content-lg-end mb-md-0 mb-2 flex-wrap">
              <Link to="/privacy-policy/">Privacy policy</Link>{" "}
              <span className="ml-2 mr-2"> | </span>{" "}
              <Link to="/terms/">Terms and Conditions</Link>
              <span className="ml-2 mr-2"> | </span>{" "}
              <Link to="/joint-venture/">Joint venture</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-pad">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-sm-6 d-flex justify-content-center justify-content-md-start">
              <Logo />
            </div>
            <div className="col-sm-6 d-flex justify-content-center justify-content-md-end">
              <img className="img-fluid" src={clogo} alt="" />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
