import willowSquareImg from '../../../images/commercial/completed/willow-square3.jpg'

import int1 from '../../../images/commercial/interior/1.jpg'
import int2 from '../../../images/commercial/interior/2.jpg'
import int3 from '../../../images/commercial/interior/3.jpg'
import int4 from '../../../images/commercial/interior/4.jpg'
import int5 from '../../../images/commercial/interior/5.jpg'
import int6 from '../../../images/commercial/interior/6.jpg'
import int7 from '../../../images/commercial/interior/7.jpg'
import int8 from '../../../images/commercial/interior/8.jpg'


import ext1 from '../../../images/commercial/exterior/3.jpg'
import ext2 from '../../../images/commercial/exterior/4.jpg'
import ext3 from '../../../images/commercial/exterior/5.jpg'
import ext4 from '../../../images/commercial/exterior/6.jpg'
import ext5 from '../../../images/commercial/exterior/7.jpg'
import ext6 from '../../../images/commercial/exterior/8.jpg'
import ext7 from '../../../images/commercial/exterior/9.jpg'
import ext8 from '../../../images/commercial/exterior/10.jpg'
import ext9 from '../../../images/commercial/exterior/11.jpg'
import ext10 from '../../../images/commercial/exterior/12.jpg'
import ext11 from '../../../images/commercial/exterior/13.jpg'
import ext12 from '../../../images/commercial/exterior/14.jpg'

export const completeddata = [
  {
    "id": 1,
    "order": null,
    "is_active": true,
    "is_custom": true,
    // "ribbon_text": "New",
    "title": "Willow Square",
    "slug": "willow-square",
    "rera": "",
    "tagline": "",
    "banner" : {
      "childImageSharp" : {
        "fluid": {
          "src" : willowSquareImg
        }
      }
    },
    "logo": "",
    "brouchureUrl":"",
    "bg": "",
    // "brouchureUrl": "/pdfs/brouchures/damera.pdf",
    // "bg": "../../../images/bgs/plutus.jpg",
    "callUsNumber": "",
    "chatCodeId": "",
    "video": {
      "type": "youtube",
      "link": ""
    },
    "short_desc": "Industrial Estate, Guindy.",
    "footer_desc": "A vibrant workspace creates a productive environment.",
    gallery2: [
      {
        title: "Exterior",
        images: [
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: ext1,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: ext2,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: ext3,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: ext4,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: ext5,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: ext6,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: ext7,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: ext8,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: ext9,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: ext10,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: ext11,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: ext12,
                srcSet: "",
              },
            },
          },
        ],
        videos: null,
      },
      {
        title: "Interior",
        images: [
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: int1,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: int2,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: int3,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: int4,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: int5,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: int6,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: int7,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: int8,
                srcSet: "",
              },
            },
          },
        ],
        videos: null,
      },
    ],
  }
]